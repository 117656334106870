const clienteInfo = (id) => {
  switch (id) {
    case "btflex":
      return {
        nome: "BTFlex",
        desde: "2021",
        logo: "https://btflex-online170714-dev.s3.us-west-2.amazonaws.com/public/btflex.png",
        logoLogIn:
          "https://btflex-online170714-dev.s3.us-west-2.amazonaws.com/public/btflex.png",
        mensagem: "Coconstruido com muito ❤️☕ para a BTFlex.",
        API: "https://btflexapi.simplesnologia.biz",
        id,
      };
    case "real":
      return {
        nome: "Nação Real",
        desde: "2021",
        logo: "https://happybiz-web.s3.sa-east-1.amazonaws.com/online/logo-real-horizontal.png",
        logoLogIn: "https://nacaoreal.online/imagesOnline/logo-padariaReal.svg",
        mensagem: "Coconstruido com muito ❤️☕🥖 para a Nação Real.",
        API: "https://apinacaoreal.simplesnologia.biz",
        id,
      };
    case "benassi":
      return {
        nome: "Benassi",
        desde: "2021",
        logo: "https://happybiz-web.s3.sa-east-1.amazonaws.com/online/benassi.png",
        logoLogIn:
          "https://happybiz-web.s3.sa-east-1.amazonaws.com/online/logo-benassi-login.png",
        mensagem: "Coconstruido com muito ❤️☕ para a Benassi.",
        API: "https://apinacaobenassi.simplesnologia.biz",
        id,
      };
    case "capitalRelacional":
      return {
        nome: "Capital Relacional",
        desde: "2021",
        logo: "https://happybiz-web.s3.sa-east-1.amazonaws.com/online/logotipo-aia.png",
        logoLogIn:"https://happybiz-geral.s3.amazonaws.com/CapitalRelacional/logo-icone.png",
        mensagem: "Coconstruido com muito ❤️☕ para a Capital Relacional.",
        API: "https://apicapitalrelacional.simplesnologia.biz",
        senhaPadrao: "hb@feliz",
        id,
      };
    default:
      return {
        nome: "Nação Real",
        desde: "2021",
        logo: "https://happybiz-web.s3.sa-east-1.amazonaws.com/online/logo-real-horizontal.png",
        logoLogIn: "https://nacaoreal.online/imagesOnline/logo-padariaReal.svg",
        mensagem: "Coconstruido com muito ❤️☕🥖 para a Nação Real.",
        id,
      };
  }
};

export default clienteInfo("real");
